import { IModule } from "../interfaces/IModule"
import { InMemoryAchievementsRepository } from "../repositories/InMemoryAchievementsRepository"
import { InMemoryAuthRepository } from "../repositories/InMemoryAuthRepository"
import { InMemoryChestRepository } from "../repositories/InMemoryChestRepository"
import { InMemoryCoinsRepository } from "../repositories/InMemoryCoinsRepository"
import { InMemoryDailyRepository } from "../repositories/InMemoryDailyRepository"
import { InMemoryLeaderboardRepository } from "../repositories/InMemoryLeaderboardRepository"
import { InMemoryLocalStorageEmailRepository } from "../repositories/InMemoryLocalStorageEmail"
import { InMemoryLocalStorageRepository } from "../repositories/InMemoryLocalStorageRepository"
import { InMemoryMarketplaceRepository } from "../repositories/InMemoryMarketplaceRepository"
import { InMemoryPointsRepository } from "../repositories/InMemoryPointsRepository"
import { InMemoryStatsRepository } from "../repositories/InMemoryStatsRepository"
import { InMemoryStreaksRepository } from "../repositories/InMemoryStreaksRepository"
import { LocalStorageNewsletterRepository } from "../repositories/LocalStorageNewsletterRepository"
import { LocalStoragePlayRepository } from "../repositories/LocalStoragePlayRepository"
import { InMemoryAnalyticsService } from "../services/InMemoryAnalyticsService"
import { InMemoryLocationService } from "../services/InMemoryLocationService"
import { InMemoryPaymentService } from "../services/InMemoryPaymentService"
import { InMemoryUniqueNameGeneratorService } from "../services/InMemoryUniqueNameGeneratorService"

export class TestModule implements IModule {
  build() {
    const PlayRepository = new LocalStoragePlayRepository()
    const NewsletterRepository = new LocalStorageNewsletterRepository()
    const StatsRepository = new InMemoryStatsRepository()
    const PointsRepository = new InMemoryPointsRepository()
    const PaymentService = new InMemoryPaymentService()
    const LeaderboardRepository = new InMemoryLeaderboardRepository()
    const MarketplaceRepository = new InMemoryMarketplaceRepository()
    const CoinsRepository = new InMemoryCoinsRepository()
    const ChestRepository = new InMemoryChestRepository()
    const AuthRepository = new InMemoryAuthRepository()
    const LocationService = new InMemoryLocationService()
    const LocalStorageEmailRepository =
      new InMemoryLocalStorageEmailRepository()
    const LocalStorageRepository = new InMemoryLocalStorageRepository()
    const DailyRepository = new InMemoryDailyRepository()
    const AnalyticsService = new InMemoryAnalyticsService()
    const UniqueNameGeneratorService = new InMemoryUniqueNameGeneratorService()
    const AchievementsRepository = new InMemoryAchievementsRepository()
    const StreaksRepository = new InMemoryStreaksRepository()

    return {
      PaymentService,
      MarketplaceRepository,
      ChestRepository,
      NewsletterRepository,
      CoinsRepository,
      LeaderboardRepository,
      LocalStorageRepository,
      AchievementsRepository,
      UniqueNameGeneratorService,
      StreaksRepository,
      PlayRepository,
      StatsRepository,
      PointsRepository,
      AuthRepository,
      DailyRepository,
      LocationService,
      AnalyticsService,
      LocalStorageEmailRepository,
    }
  }
}
