import dayjs from "dayjs"
import { StreakEntity } from "../entities/StreakEntity"
import { IStreaksRepository } from "../interfaces/IStreaksRepository"

export class InMemoryStreaksRepository implements IStreaksRepository {
  private streaks: Map<string, StreakEntity[]> = new Map()

  async __store(streaks: StreakEntity[]) {
    for (const streak of streaks) {
      await this.create(streak)
    }
  }

  async create(params: Omit<StreakEntity, "id">): Promise<StreakEntity> {
    const id = dayjs(params.date).format("YYYY-MM-DD")

    this.streaks.set(params.user_id, [
      ...(this.streaks.get(params.user_id) || []),
      { ...params, id },
    ])

    return { ...params, id }
  }

  async fetch(params: {
    user_id: string
    interval?: { start: Date; end: Date }
  }): Promise<StreakEntity[]> {
    return this.streaks.get(params.user_id) || []
  }
}
