import {
  StatByLevelDisplayable,
  StatDisplayable,
  StatEntity,
  StatHistoryDisplayable,
  StatTotalDisplayable,
} from "../../entities/StatEntity"
import { IStatsRepositoryPeriod } from "../../interfaces/IStatsRepository"
import { StatsAggregationService } from "../../services/StatsAggregationService"
import * as types from "./types"

interface StatsState {
  fetching: boolean
  stats: StatDisplayable[]
  history: StatEntity[]
  total: StatTotalDisplayable
  period: IStatsRepositoryPeriod
  byLevel: { [key: string]: StatByLevelDisplayable }
}

const initialState: StatsState = {
  fetching: false,
  total: { games: 0, errors: 0, duration: 0 },
  stats: [],
  period: "week",
  byLevel: {
    easy: { games: 0, errors: 0, duration: 0, level: "easy" },
    medium: { games: 0, errors: 0, duration: 0, level: "medium" },
    hard: { games: 0, errors: 0, duration: 0, level: "hard" },
    expert: { games: 0, errors: 0, duration: 0, level: "expert" },
    evil: { games: 0, errors: 0, duration: 0, level: "evil" },
  },
  history: [],
}

export function statsReducer(
  state = initialState,
  action: types.StatsActionTypes
): StatsState {
  if (action.type === types.Fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === types.FetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  if (action.type === types.ChangePeriod) {
    return {
      ...state,
      period: action.payload.period,
    }
  }

  if (action.type === types.Store) {
    const stats = StatsAggregationService.aggregate({
      endDate: new Date(),
      period: state.period,
      stats: action.payload.stats,
    })

    const total = StatsAggregationService.aggregateTotal({
      stats: action.payload.stats,
    })

    const byLevel = StatsAggregationService.aggregateByLevels({
      stats: action.payload.stats,
    })

    return {
      ...state,
      stats,
      history: action.payload.stats
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 50),
      total,
      byLevel,
    }
  }

  return state
}
