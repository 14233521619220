import { UserEntity } from "../../entities/UserEntity"
import * as types from "./types"
import { lensPath, set, view } from "ramda"
import {
  AuthenticateWithLinkReturnErrorType,
  SendSignInLinkToEmailErrorType,
} from "../../interfaces/IAuthRepository"

export const FlowsSigninEmailStateCodes = {
  BAD_FORMED: "BAD_FORMED",
  ALREADY_USED: "ALREADY_USED",
  NOT_EXISTS: "NOT_EXISTS",
  NOTHING: "NOTHING",
  GOOD: "GOOD",
}

export type FlowsSigninEmailStateCodesTypes =
  keyof typeof FlowsSigninEmailStateCodes

export const FlowsSigninLinkEmailStateCodes = {
  NOT_EXISTS: "NOT_EXISTS",
  NOTHING: "NOTHING",
  GOOD: "GOOD",
}

export type FlowsSigninLinkEmailStateCodesTypes =
  keyof typeof FlowsSigninLinkEmailStateCodes

export const FlowsSignupUsernameStateCodes = {
  ALREADY_USED: "ALREADY_USED",
  EMPTY: "EMPTY",
  NOTHING: "NOTHING",
  GOOD: "GOOD",
}
export type FlowsSignupUsernameStateCodesTypes =
  keyof typeof FlowsSignupUsernameStateCodes

export const FlowsSigninPasswordStateCodes = {
  NOTHING: "NOTHING",
  NOT_THE_SAME: "NOT_THE_SAME",
  BAD_PASSWORD: "BAD_PASSWORD",
  EMPTY: "EMPTY",
  WEAK: "WEAK",
  GOOD: "GOOD",
}

export type FlowsSigninPasswordStateCodesTypes =
  keyof typeof FlowsSigninPasswordStateCodes

export const FlowsSigninLinkValidationPasswordStateCodes = {
  NOTHING: "NOTHING",
  EMPTY: "EMPTY",
  WEAK: "WEAK",
  GOOD: "GOOD",
}

export type FlowsSigninLinkValidationPasswordStateCodesTypes =
  keyof typeof FlowsSigninLinkValidationPasswordStateCodes

interface AuthState {
  user: UserEntity | null
  will_be_removed_at: Date | null
  will_be_removed_fetching: boolean
  is_fetching: boolean
  flows: {
    avatar: {
      modalIsOpen: boolean
      fetching: boolean
      selected: string | null
    }
    username: {
      modalIsOpen: boolean
      fetching: boolean
      value: string | null
    }
    signin: {
      steps: {
        current: number
        number: number
      }
      process: {
        fetching: boolean
        error: SendSignInLinkToEmailErrorType | null
        success: boolean
        forgotSuccess: boolean
      }
      form: {
        email: {
          focus: boolean
          value: string
          state: {
            code: FlowsSigninEmailStateCodesTypes
          }
        }
      }
    }
    signinLinkValidation: {
      steps: {
        current: number
        number: number
      }
      process: {
        succeed: boolean
        fetching: boolean
        error: AuthenticateWithLinkReturnErrorType | null
      }
      form: {
        email: {
          focus: boolean
          value: string
          state: {
            code: FlowsSigninEmailStateCodesTypes
          }
        }
      }
    }
  }
  authenticated: boolean
}

const initialState: AuthState = {
  user: null,
  will_be_removed_at: null,
  will_be_removed_fetching: false,
  authenticated: false,
  is_fetching: false,
  flows: {
    signinLinkValidation: {
      steps: {
        current: 0,
        number: 1,
      },
      process: {
        succeed: false,
        fetching: false,
        error: null,
      },
      form: {
        email: {
          focus: false,
          value: "",
          state: {
            code: FlowsSigninEmailStateCodes[
              "NOTHING"
            ] as FlowsSigninEmailStateCodesTypes,
          },
        },
      },
    },
    signin: {
      steps: {
        current: 0,
        number: 1,
      },
      process: {
        fetching: false,
        error: null,
        success: false,
        forgotSuccess: false,
      },
      form: {
        email: {
          focus: false,
          value: "",
          state: {
            code: FlowsSigninEmailStateCodes[
              "NOTHING"
            ] as FlowsSigninEmailStateCodesTypes,
          },
        },
      },
    },
    avatar: {
      modalIsOpen: false,
      fetching: false,
      selected: null,
    },
    username: {
      modalIsOpen: false,
      fetching: false,
      value: null,
    },
  },
}

export function authReducer(
  state = initialState,
  action: types.AuthActionTypes
): AuthState {
  if (action.type === types.authenticate) {
    return {
      ...state,
      authenticated: true,
      user: action.payload.user,
      flows: {
        ...state.flows,
        avatar: {
          ...state.flows.avatar,
          selected: action.payload.user.avatar,
        },
        username: {
          ...state.flows.username,
          value: action.payload.user.username,
        },
      },
    }
  }

  if (action.type === types.authenticateFetching) {
    return {
      ...state,
      is_fetching: action.payload.is_fetching,
    }
  }

  if (action.type === types.setWillBeRemovedAt) {
    return {
      ...state,
      will_be_removed_at: action.payload.will_be_removed_at,
    }
  }

  if (action.type === types.setWillBeRemovedAtFetching) {
    return {
      ...state,
      will_be_removed_fetching: action.payload.is_fetching,
    }
  }

  /**
   * ANCHOR Signin
   */
  if (action.type === types.FlowsSigninFormEmailUpdateType) {
    const path = lensPath(["flows", "signin", "form", "email", "value"])
    const code = lensPath(["flows", "signin", "form", "email", "state", "code"])

    return set(code, "NOTHING", set(path, action.payload.email, state))
  }

  if (action.type === types.FlowsSigninFormEmailCheckType) {
    const path = lensPath(["flows", "signin", "form", "email", "state", "code"])
    const email = state.flows.signin.form.email.value

    if (!/[a-zA-Z.-_0-9]+@[a-zA-Z.-_0-9]+\.[a-z]+/gi.test(email))
      return set(path, "BAD_FORMED", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsSigninProcessSucceedUpdateType) {
    const path = lensPath(["flows", "signin", "process", "success"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsResetType) {
    return {
      ...state,
      flows: { ...initialState.flows },
    }
  }

  if (action.type === types.FlowsSigninProcessFetchingType) {
    const path = lensPath(["flows", "signin", "process", "fetching"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsSigninProcessFetchEndType) {
    const path = lensPath(["flows", "signin", "process", "fetching"])

    return set(path, false, state)
  }

  if (action.type === types.FlowsSigninProcessErrorSetType) {
    const path = lensPath(["flows", "signin", "process", "error"])

    return set(path, action.payload.error, state)
  }

  if (action.type === types.FlowsSigninFormEmailUpdateFocusType) {
    const path = lensPath(["flows", "signin", "form", "email", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSigninFormEmailUpdateStateType) {
    const path = lensPath(["flows", "signin", "form", "email", "state", "code"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSigninStepsNextType) {
    const path = lensPath(["flows", "signin", "steps", "current"])
    const next = (view(path, state) as number) + 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsSigninStepsPreviousType) {
    const path = lensPath(["flows", "signin", "steps", "current"])
    const current = state.flows.signin.steps.current
    const next = current === 0 ? 0 : current - 1

    return set(path, next, state)
  }

  /**
   * ANCHOR Signin Link Validation
   */

  if (action.type === types.FlowsSigninLinkValidationProcessSuccessUpdateType) {
    const path = lensPath([
      "flows",
      "signinLinkValidation",
      "process",
      "succeed",
    ])

    return set(path, true, state)
  }

  if (action.type === types.FlowsSigninLinkValidationFormEmailUpdateType) {
    const path = lensPath([
      "flows",
      "signinLinkValidation",
      "form",
      "email",
      "value",
    ])
    const code = lensPath([
      "flows",
      "signinLinkValidation",
      "form",
      "email",
      "state",
      "code",
    ])

    return set(code, "NOTHING", set(path, action.payload.email, state))
  }

  if (action.type === types.FlowsSigninLinkValidationFormEmailUpdateFocusType) {
    const path = lensPath([
      "flows",
      "signinLinkValidation",
      "form",
      "email",
      "focus",
    ])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSigninLinkValidationFormEmailCheckType) {
    const path = lensPath([
      "flows",
      "signinLinkValidation",
      "form",
      "email",
      "state",
      "code",
    ])
    const email = state.flows.signinLinkValidation.form.email.value

    if (!/[a-zA-Z.-_0-9]+@[a-zA-Z.-_0-9]+\.[a-z]+/gi.test(email))
      return set(path, "BAD_FORMED", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsSigninLinkValidationProcessFetchingType) {
    const path = lensPath([
      "flows",
      "signinLinkValidation",
      "process",
      "fetching",
    ])

    return set(path, true, state)
  }

  if (action.type === types.FlowsSigninLinkValidationProcessFetchEndType) {
    const path = lensPath([
      "flows",
      "signinLinkValidation",
      "process",
      "fetching",
    ])

    return set(path, false, state)
  }

  if (action.type === types.FlowsSigninLinkValidationStepsNextType) {
    const path = lensPath(["flows", "signinLinkValidation", "steps", "current"])
    const next = (view(path, state) as number) + 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsSigninLinkValidationStepsPreviousType) {
    const path = lensPath(["flows", "signinLinkValidation", "steps", "current"])
    const current = state.flows.signinLinkValidation.steps.current
    const next = current === 0 ? 0 : current - 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsSigninLinkValidationProcessSetErrorType) {
    const path = lensPath(["flows", "signinLinkValidation", "process", "error"])

    return set(path, action.payload.error, state)
  }

  /**
   * ANCHOR Avatar
   */

  if (action.type === types.FlowsAvatarProcessSetModalOpenState) {
    const path = lensPath(["flows", "avatar", "modalIsOpen"])

    return set(path, action.payload.isOpen, state)
  }

  if (action.type === types.FlowsAvatarProcessUpdateValue) {
    const path = lensPath(["flows", "avatar", "selected"])

    return set(path, action.payload.value, state)
  }

  if (action.type === types.FlowsAvatarProcessSetFetching) {
    const path = lensPath(["flows", "avatar", "fetching"])

    return set(path, action.payload.fetching, state)
  }

  /**
   * ANCHOR Username
   */

  if (action.type === types.FlowsUsernameProcessSetModalOpenState) {
    const path = lensPath(["flows", "username", "modalIsOpen"])

    return set(path, action.payload.isOpen, state)
  }

  if (action.type === types.FlowsUsernameProcessUpdateValue) {
    const path = lensPath(["flows", "username", "value"])

    return set(path, action.payload.value, state)
  }

  if (action.type === types.FlowsUsernameProcessSetFetching) {
    const path = lensPath(["flows", "username", "fetching"])

    return set(path, action.payload.fetching, state)
  }

  /**
   * ANCHOR Global
   */

  if (action.type === types.logout) {
    return { ...initialState }
  }

  return state
}
