/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/css/index.css"

import "firebase/auth"
import "firebase/firestore"

import wrapWithProvider from "./wrap-root-element"
import wrapWithIntl from "./wrap-page-element"

export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithIntl

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  return !location.state?.preventScrollReset
}
