import stats from "../urls/stats.json"
import leaderboard from "../urls/leaderboard.json"
import lessons from "../urls/lessons.json"
import levels from "../urls/levels.json"
import daily from "../urls/daily.json"
import share from "../urls/share.json"
import blog from "../urls/blog.json"
import signin from "../urls/signin.json"
import print from "../urls/print.json"

export const normalizeUrl = (lang: string, url: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  if (lang_key === "en") return `${url}`
  return `/${lang_key}${url}`
}

export const getPremiumUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  if (lang_key === "en") return `/pricing/`

  return `/${lang_key}/pricing/`
}

export const getTermsOfServiceUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, "/terms-of-service/")
}

export const getRefundPolicyUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, "/refund-policy/")
}

export const getSigninUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/signin/`)
}

export const getSignupUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/signup/`)
}

export const getResetPasswordUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/reset-password/`)
}

export const getForgotPasswordUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/forgot-password/`)
}

export const getSigninLinkValidationUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(
    lang_key,
    `${getSigninUrl(lang_key)}${signin[lang_key].validation}/`
  )
}

export const getLessonsUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/${leaderboard[lang_key]}/`)
}

export const getLeaderboardUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/${leaderboard[lang_key]}/`)
}

export const getBlogUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/${blog[lang_key]}/`)
}

export const getBlogArticleUrl = (lang: string, url: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/blog${url}`)
}

export const navigateToStats = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/${stats[lang_key]}/`)
}

export const getShareUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return normalizeUrl(lang_key, `/${share[lang_key]}/`)
}

export const oldNavigateToLessons = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en"
    ? `/${lessons[lang_key].index}/`
    : `/${lang_key}/${lessons[lang_key].index}/`
}

export const navigateToLessons = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en" ? `/learn/` : `/${lang_key}/learn/`
}

export const getLessonUrl = ({
  lang,
  url,
}: {
  lang: string
  url: string
}): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return `${navigateToLessons(lang_key)}${url}/`
}

export const getCourseUrl = ({
  lang,
  level,
}: {
  lang: string
  level: string
}): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return `${navigateToLessons(lang_key)}${levels[lang_key][level]}/`
}

export const getCoursesUrl = ({ lang }: { lang: string }): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en"
    ? `/${lessons[lang_key].index}/`
    : `/${lang_key}/${lessons[lang_key].index}/`
}

export const navigateToHome = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en" ? "/" : `/${lang_key}/`
}

export const getLevelUrl = (lang: string, level: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  const url =
    lang_key === "en"
      ? `/${levels[lang_key][level]}/`
      : `/${lang_key}/${levels[lang_key][level]}/`

  return url
}

export const getDailyUrl = (lang: string, level: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  const url =
    lang_key === "en"
      ? `/${daily[lang_key]}/${levels[lang_key][level]}/`
      : `/${lang_key}/${daily[lang_key]}/${levels[lang_key][level]}/`

  return url
}

export const getPrintUrl = (lang: string, level: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return getLevelUrl(lang_key, level) + print[lang_key] + "/"
}

export const getMarkeplaceUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en" ? `/marketplace/` : `/${lang_key}/marketplace/`
}

export const getProfileUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en" ? `/profile/` : `/${lang_key}/profile/`
}

export const getAchievementsUrl = (lang: string): string => {
  const lang_key = process.env.IS_NATIVE === "true" ? "en" : lang

  return lang_key === "en" ? `/achievements/` : `/${lang_key}/achievements/`
}
