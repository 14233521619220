import React, { useEffect, useState } from "react"

import { IntlProvider } from "react-intl"

import en from "../../i18n/messages/en.json"
import fr from "../../i18n/messages/fr.json"
import pt from "../../i18n/messages/pt.json"
import ru from "../../i18n/messages/ru.json"
import ja from "../../i18n/messages/ja.json"
import es from "../../i18n/messages/es.json"
import it from "../../i18n/messages/it.json"
import ar from "../../i18n/messages/ar.json"
import de from "../../i18n/messages/de.json"
import ko from "../../i18n/messages/ko.json"
import pl from "../../i18n/messages/pl.json"
import th from "../../i18n/messages/th.json"
import tr from "../../i18n/messages/tr.json"
import vi from "../../i18n/messages/vi.json"
import zh from "../../i18n/messages/zh.json"
import {
  connector,
  ContainerProps,
} from "./containers/CustomIntlProvider.containers"
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorage-keys"

const messages = { en, fr, pt, ru, ja, es, it, ar, de, ko, pl, th, tr, vi, zh }

const getLangFromNavigatorOrLocalStorage = () => {
  const langFromLocalStorage = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.USER_LANGUAGE
  )

  if (
    langFromLocalStorage &&
    Object.keys(messages).includes(langFromLocalStorage)
  ) {
    return langFromLocalStorage
  }

  const langWithoutLocale = navigator.language.split("-")[0]

  if (Object.keys(messages).includes(langWithoutLocale))
    return langWithoutLocale

  return "en"
}

export const Wrapper: React.FC<ContainerProps> = (props) => {
  const { langKey } = props
  const [dynamicLanguage, setDynamicLanguage] = useState(langKey)

  useEffect(() => {
    const locale = props.setDynamicLanguage
      ? getLangFromNavigatorOrLocalStorage()
      : langKey

    setDynamicLanguage(locale)

    props.onMount(locale)
  }, [])

  return (
    <IntlProvider
      locale={dynamicLanguage || langKey}
      messages={messages[dynamicLanguage || langKey]}
    >
      {props.children}
    </IntlProvider>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const CustomIntlProvider = connector(Container)
