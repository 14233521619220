import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"
import { IS_NATIVE } from "../../hooks/useEnv"
import packageJson from "../../../package.json"

export const storeIsNativeApp = (
  payload: types.storeIsNativeAppAction["payload"],
): types.GlobalActionTypes => ({
  type: types.storeIsNativeApp,
  payload,
})

export const set_deployed_git_version = (
  payload: types.global_set_deployed_git_version_action["payload"],
): types.GlobalActionTypes => ({
  type: types.global_set_deployed_git_version,
  payload,
})

export const $init =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    dispatch(actions.darkMode.$init())
    dispatch(actions.global.storeIsNativeApp({ isNativeApp: IS_NATIVE }))
    dispatch(actions.global.$check_if_native_app_is_outdated())
    dispatch(actions.global.$fetch_if_update_is_available())
    dispatch(actions.auth.$isAuthenticated()).then(() => {
      dispatch(actions.points.$fetch())
      dispatch(actions.daily.$fetch_streaks())
      dispatch(actions.achievements.$fetchAll())
      dispatch(actions.points.$fetchLeaderboard())
      dispatch(actions.coins.$fetch())
      dispatch(actions.chests.$fetch())
      dispatch(actions.marketplace.$fetch())
      dispatch($initRefresh())
      dispatch($shouldShowAds())
      dispatch(actions.newsletter.$fetchNewsletter())
    })
  }

export const storeAds = (
  payload: types.storeAdsAction["payload"],
): types.GlobalActionTypes => ({
  type: types.storeAds,
  payload,
})

export const storeIsPremium = (
  payload: types.storeIsPremiumAction["payload"],
): types.GlobalActionTypes => ({
  type: types.storeIsPremium,
  payload,
})

export const $shouldShowAds =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { auth } = getState()

    if (!auth.user?.id) return dispatcher(storeAds({ showAds: true }))

    await dispatcher(actions.pricing.$fetchState())

    const { pricing } = getState()
    if (pricing.isPremium) return dispatcher(storeAds({ showAds: false }))

    return dispatcher(storeAds({ showAds: true }))
  }

export const $refreshAds =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { global } = getState()

    // if (global.ads.show) {
    //   dispatcher(storeAds({ showAds: false }))

    //   setTimeout(() => {
    //     dispatcher(storeAds({ showAds: true }))
    //   }, 100)
    // }
  }

export const $initRefresh =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    setInterval(
      () => {
        dispatcher($shouldRefresh())
      },
      1000 * 60 * 10,
    )
  }

export const $fetch_if_update_is_available = () => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, types.GlobalActionTypes>,
    getState: () => RootState,
  ) => {
    const every_10_minutes = 1000 * 60 * 10

    const run = async () => {
      try {
        const request = await fetch(
          "https://www.sudoku.academy/web-version.json",
        )

        const data: {
          version: string
          build_time: string
        } = await request.json()

        dispatch(
          set_deployed_git_version({
            current_web_version: packageJson.version,
            online_web_version: data.version,
          }),
        )
      } catch (error) {
        console.error("Failed to check web version:", error)
      }
    }

    run()

    setInterval(run, every_10_minutes)
  }
}

export const set_online_native_version = (
  payload: types.global_set_online_native_version_action["payload"],
): types.GlobalActionTypes => ({
  type: types.global_set_online_native_version,
  payload,
})

export const $check_if_native_app_is_outdated = () => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, types.GlobalActionTypes>,
    getState: () => RootState,
  ) => {
    const every_hours = 1000 * 60 * 60

    const check = async () => {
      try {
        const request = await fetch(
          "https://www.sudoku.academy/native-version.json",
        )
        const data: {
          version: string
        } = await request.json()

        dispatch(
          set_online_native_version({
            online_native_version: data.version,
            current_native_version: packageJson.native_version,
          }),
        )
      } catch (error) {
        console.error("Failed to check native app version:", error)
      }
    }

    check()
    setInterval(check, every_hours)
  }
}

export const $shouldRefresh =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(actions.marketplace.refresh())
  }

export const $reload = () => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, types.GlobalActionTypes>,
    getState: () => RootState,
  ) => {
    const { di } = getState()

    di.AnalyticsService.send({
      category: "global",
      action: "reload",
    })

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
}
