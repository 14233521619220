import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

type Props = {
  children: React.ReactNode
}

const mapState = (state: RootState, props: Props) => ({
  ...props,
})

const mapDispatch = (dispatch: any, props: Props) => ({
  onMount: () => {
    dispatch(actions.global.$init())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
