import React, { ReactElement, useEffect } from "react"
import {
  connector,
  ContainerProps,
} from "./containers/StaticInitialization.container"
import { StreakModal } from "../streak-modal/streak-modal"

export const Wrapper: React.FC<ContainerProps> = (props) => {
  useEffect(() => {
    setTimeout(() => props.onMount())
  }, [])

  return <>{props.children}</>
}

export const Container: React.FC<ContainerProps> = (props) => (
  <>
    <Wrapper {...props} />
  </>
)

export const StaticInitialization = connector(Container)
