import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import {
  getSigninLinkValidationUrl,
  getSigninUrl,
  navigateToHome,
} from "../../i18n/utils/getUrl"
import { actions } from "../actions"
import { getRandomAvatar } from "../../utils/getRandomAvatar"
import {
  UserEntity,
  UserWithOptionalProfileEntity,
} from "../../entities/UserEntity"
import { Modules } from "../../interfaces/IModule"
import { closeDrawer } from "../../utils/drawerUtils"
import { removeHash } from "../../utils/manage-modals"
import { loadHotjar } from "../../utils/loadHotjar"

export const open = () => ({
  type: types.open,
})

export const close = () => ({
  type: types.close,
})

export const authenticateFetching = (
  payload: types.authenticateFetchingAction["payload"]
): types.AuthActionTypes => ({
  type: types.authenticateFetching,
  payload,
})

export const authenticate = (
  payload: types.authenticateAction["payload"]
): types.AuthActionTypes => ({
  type: types.authenticate,
  payload,
})

export const edit = (
  payload: types.editAction["payload"]
): types.AuthActionTypes => ({
  type: types.edit,
  payload,
})

export const forgot = (): types.AuthActionTypes => ({
  type: types.forgot,
})

export const logout = (): types.AuthActionTypes => ({
  type: types.logout,
})

export const $logout =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    dispatcher(logout())

    await di.AuthRepository.logout()
    await di.AnalyticsService.logout()

    di.LocationService.reload()
  }

export const fetching = (): types.AuthActionTypes => ({
  type: types.fetching,
})

export const fetchEnd = (): types.AuthActionTypes => ({
  type: types.fetchEnd,
})

const addUserToALeagueIfNeeded = async (props: {
  di: Modules
  user: UserWithOptionalProfileEntity
}) => {
  const userLeague = await props.di.LeaderboardRepository.getUserLeague({
    userId: props.user.id,
  })

  if (userLeague.length !== 0) return true

  const meta = await props.di.LeaderboardRepository.getMeta()
  const bracketsNumber = meta.brackets[0] - 1 || 0
  const bracketId = Math.round(Math.random() * bracketsNumber)

  await props.di.LeaderboardRepository.storeUserOnLeague({
    points: 0,
    league: 0,
    bracket: bracketId,
    userId: props.user.id,
    avatar: props.user.avatar,
    username: props.user.username,
  })
}

const updateProfileIfNeeded = async (props: {
  di: Modules
  user: UserWithOptionalProfileEntity
}): Promise<UserEntity> => {
  const username =
    props.user.username ||
    (await (
      await props.di.UniqueNameGeneratorService.get()
    ).name)

  const avatar = props.user.avatar || getRandomAvatar()

  if (props.user.avatar && props.user.username)
    return {
      ...props.user,
      username,
      avatar,
    }

  await props.di.AuthRepository.updateProfile({
    username,
    avatar,
  })

  await props.di.PointsRepository.updateProfile({
    username,
    avatar,
  })

  await addUserToALeagueIfNeeded({
    di: props.di,
    user: {
      ...props.user,
      username,
      avatar,
    },
  })

  return {
    ...props.user,
    username,
    avatar,
  }
}

export const $isAuthenticated =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    const response = await di.AuthRepository.isAuthenticated()

    if (response.authenticated) {
      const user = await updateProfileIfNeeded({
        user: response.user,
        di,
      })

      dispatcher(authenticate({ user }))
      di.AnalyticsService.authenticate({ id: response.user.id })

      if (response.user.id === "sqRTHfQVfyTb1ZYsoBTYmZAz7b52") {
        loadHotjar()
      }
    }
  }

export const $authenticateWithGoogle =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, lang } = getState()

    const response = await di.AuthRepository.authenticateWithGoogle()

    if (response.authenticated) {
      di.LocationService.navigate(navigateToHome(lang.lang))

      await updateProfileIfNeeded({
        user: response.user,
        di,
      })

      di.AnalyticsService.send({
        action: "google",
        category: "authentication",
      })

      dispatcher(actions.global.$init())

      dispatcher(
        actions.notifications.create({
          type: "success",
          message: "login",
        })
      )
    }
  }

export const $authenticateWithPassword =
  (params: {
    email: string
    password: string
  }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState()

    dispatcher(authenticateFetching({ is_fetching: true }))

    const response = await di.AuthRepository.authenticateWithPassword(params)

    dispatcher(authenticateFetching({ is_fetching: false }))

    if (response.authenticated === false) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.error,
        })
      )
    }

    di.AnalyticsService.send({
      action: "password",
      category: "authentication",
    })

    di.LocationService.navigate(navigateToHome(lang.lang))
    di.LocationService.reload()
  }

export const $createAccountWithPassword =
  (params: {
    email: string
    password: string
  }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState()

    dispatcher(authenticateFetching({ is_fetching: true }))

    const response = await di.AuthRepository.createAccountWithPassword(params)

    dispatcher(authenticateFetching({ is_fetching: false }))

    if (response.success === false) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.error,
        })
      )
    }

    di.AnalyticsService.send({
      action: "signup/password",
      category: "authentication",
    })

    dispatcher(
      actions.notifications.create({
        type: "success",
        message: "auth/signup/password/success",
      })
    )

    di.LocationService.navigate(getSigninUrl(lang.lang))
  }

export const setWillBeRemovedAt = (
  payload: types.setWillBeRemovedAtAction["payload"]
): types.AuthActionTypes => ({
  type: types.setWillBeRemovedAt,
  payload,
})

export const setWillBeRemovedAtFetching = (
  payload: types.setWillBeRemovedAtFetchingAction["payload"]
): types.AuthActionTypes => ({
  type: types.setWillBeRemovedAtFetching,
  payload,
})

export const $fetchRemoveProfile =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(setWillBeRemovedAtFetching({ is_fetching: true }))

    const response = await di.AuthRepository.getRequestRemoveProfile({
      user_id: auth.user.id,
    })

    dispatcher(setWillBeRemovedAtFetching({ is_fetching: false }))

    if (response.success === true) {
      dispatcher(
        setWillBeRemovedAt({
          will_be_removed_at: response.body.will_be_removed_at,
        })
      )
    } else {
      dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.error,
        })
      )
    }
  }

export const $requestRemoveProfile =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(setWillBeRemovedAtFetching({ is_fetching: true }))

    const response = await di.AuthRepository.storeRequestRemoveProfile({
      user_id: auth.user.id,
      will_be_removed_at: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30), // in 30 days
    })

    dispatcher(setWillBeRemovedAtFetching({ is_fetching: false }))

    if (response.success === true) {
      dispatcher(
        setWillBeRemovedAt({
          will_be_removed_at: response.body.will_be_removed_at,
        })
      )

      di.LocationService.navigate(
        removeHash({
          hashToRemove: "remove-profile-modal",
          currentHash: di.LocationService.getFullUrl(),
        })
      )

      dispatcher(
        actions.notifications.create({
          type: "success",
          message: "notifications/profile/remove/success",
        })
      )
    } else {
      dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.error,
        })
      )
    }
  }

export const $undoRemoveProfile =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(setWillBeRemovedAtFetching({ is_fetching: true }))

    const response = await di.AuthRepository.removeRequestRemoveProfile({
      user_id: auth.user.id,
    })

    dispatcher(setWillBeRemovedAtFetching({ is_fetching: false }))

    if (response.success === true) {
      dispatcher(setWillBeRemovedAt({ will_be_removed_at: null }))

      dispatcher(
        actions.notifications.create({
          type: "success",
          message: "notifications/profile/remove-undo/success",
        })
      )
    } else {
      dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.error,
        })
      )
    }
  }

export const flows = {
  // ANCHOR Global
  reset: (): types.AuthActionTypes => ({
    type: types.FlowsResetType,
  }),
  // ANCHOR Signin
  signin: {
    steps: {
      next: (): types.AuthActionTypes => ({
        type: types.FlowsSigninStepsNextType,
      }),
      previous: (): types.AuthActionTypes => ({
        type: types.FlowsSigninStepsPreviousType,
      }),
      fetchNext:
        (): ThunkAction<any, RootState, any, any> =>
        async (dispatcher, getState) => {
          return dispatcher(flows.signin.form.email.next())
        },
    },
    process: {
      fetching: () => ({ type: types.FlowsSigninProcessFetchingType }),
      fetchEnd: () => ({ type: types.FlowsSigninProcessFetchEndType }),
      success: {
        update: (
          payload: types.FlowsSigninProcessSucceedUpdateAction["payload"]
        ) => ({
          type: types.FlowsSigninProcessSucceedUpdateType,
          payload,
        }),
      },
      error: {
        set: (payload: types.FlowsSigninProcessErrorSetAction["payload"]) => ({
          type: types.FlowsSigninProcessErrorSetType,
          payload,
        }),
      },
    },
    form: {
      email: {
        update: (
          payload: types.FlowsSigninFormEmailUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSigninFormEmailUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSigninFormEmailCheckType,
        }),
        state: {
          update: (
            payload: types.FlowsSigninFormEmailUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninFormEmailUpdateStateType,
            payload,
          }),
        },
        focus: {
          update: (
            payload: types.FlowsSigninFormEmailUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninFormEmailUpdateFocusType,
            payload,
          }),
        },

        next:
          (): ThunkAction<any, RootState, any, any> =>
          async (dispatcher, getState) => {
            dispatcher(flows.signin.process.fetching())
            dispatcher(flows.signin.form.email.check())

            const { auth } = getState()

            if (auth.flows.signin.form.email.state.code === "GOOD") {
              dispatcher(flows.signin.submit.execute())
            } else {
              dispatcher(flows.signin.process.fetchEnd())
            }
          },
      },
    },
    submit: {
      execute:
        (): ThunkAction<any, RootState, any, any> =>
        async (dispatcher, getState) => {
          const { auth, di, lang } = getState()

          dispatcher(flows.signin.process.fetching())

          const email = auth.flows.signin.form.email.value

          const response = await di.AuthRepository.sendSignInLinkToEmail({
            email,
            lang: lang.lang,
            destinationUrl:
              di.LocationService.getOrigin() +
              getSigninLinkValidationUrl(lang.lang),
          })

          dispatcher(flows.signin.process.fetchEnd())

          if (response.succeed) {
            await di.LocalStorageEmailRepository.store(email)
            di.AnalyticsService.send({
              action: "send_link",
              category: "authentication",
            })
            dispatcher(flows.signin.process.success.update({ state: true }))
          } else {
            dispatcher(
              flows.signin.process.error.set({
                // @ts-ignore
                error: response.error,
              })
            )

            di.AnalyticsService.send({
              category: "error",
              action: "send_link",
              // @ts-ignore
              message: response.sourceErrorCode || "",
            })
          }
        },
    },
  },
  // ANCHOR Signin Link Validation
  signinLinkValidation: {
    steps: {
      next: (): types.AuthActionTypes => ({
        type: types.FlowsSigninLinkValidationStepsNextType,
      }),
      previous: (): types.AuthActionTypes => ({
        type: types.FlowsSigninLinkValidationStepsPreviousType,
      }),
    },
    process: {
      succeed: {
        update: (
          payload: types.FlowsSigninLinkValidationProcessSuccessUpdateAction["payload"]
        ) => ({
          type: types.FlowsSigninLinkValidationProcessSuccessUpdateType,
          payload,
        }),
      },
      error: {
        set: (
          payload: types.FlowsSigninLinkValidationProcessSetErrorAction["payload"]
        ) => ({
          type: types.FlowsSigninLinkValidationProcessSetErrorType,
          payload,
        }),
      },
      fetching: () => ({
        type: types.FlowsSigninLinkValidationProcessFetchingType,
      }),
      fetchEnd: () => ({
        type: types.FlowsSigninLinkValidationProcessFetchEndType,
      }),
    },
    form: {
      email: {
        update: (
          payload: types.FlowsSigninLinkValidationFormEmailUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSigninLinkValidationFormEmailUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSigninLinkValidationFormEmailCheckType,
        }),
        state: {
          update: (
            payload: types.FlowsSigninLinkValidationFormEmailUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninLinkValidationFormEmailUpdateStateType,
            payload,
          }),
        },
        focus: {
          update: (
            payload: types.FlowsSigninLinkValidationFormEmailUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninLinkValidationFormEmailUpdateFocusType,
            payload,
          }),
        },

        next:
          (): ThunkAction<any, RootState, any, any> =>
          async (dispatcher, getState) => {
            dispatcher(flows.signinLinkValidation.process.fetching())
            dispatcher(flows.signinLinkValidation.form.email.check())

            const { auth } = getState()

            if (
              auth.flows.signinLinkValidation.form.email.state.code === "GOOD"
            ) {
              dispatcher(flows.signinLinkValidation.process.fetchEnd())
              dispatcher(flows.signinLinkValidation.submit.execute())
            } else {
              dispatcher(flows.signinLinkValidation.process.fetchEnd())
            }
          },
      },
    },
    submit: {
      execute:
        (): ThunkAction<any, RootState, any, any> =>
        async (dispatcher, getState) => {
          const { auth, di } = getState()

          const localstorageEmail = await di.LocalStorageEmailRepository.get()
          const emailHasBeenAddedByUser =
            auth.flows.signinLinkValidation.form.email.state.code === "GOOD"

          if (!localstorageEmail && !emailHasBeenAddedByUser) {
            return dispatcher(flows.signinLinkValidation.steps.next())
          }

          const email = emailHasBeenAddedByUser
            ? auth.flows.signinLinkValidation.form.email.value
            : (localstorageEmail as string)

          const response = await di.AuthRepository.authenticateWithSigninLink({
            email,
            link: di.LocationService.getFullUrl(),
          })

          if (response.authenticated) {
            const user = await updateProfileIfNeeded({
              user: response.user,
              di,
            })

            dispatcher(
              authenticate({
                user,
              })
            )

            dispatcher(
              flows.signinLinkValidation.process.succeed.update({
                state: true,
              })
            )

            di.AnalyticsService.send({
              category: "authentication",
              action: "login_with_link",
            })
            di.AnalyticsService.authenticate({ id: response.user.id })
            dispatcher(actions.global.$init())
          } else {
            if (auth.flows.signinLinkValidation.steps.current === 0) {
              dispatcher(flows.signinLinkValidation.steps.next())
            } else {
              dispatcher(
                flows.signinLinkValidation.process.error.set({
                  // @ts-ignore
                  error: response.error,
                })
              )

              di.AnalyticsService.send({
                category: "error",
                action: "login_with_link",
                // @ts-ignore
                message: response.sourceErrorCode || "",
              })
            }
          }
        },
    },
  },

  // ANCHOR Avatar Update
  avatar: {
    setModalOpen: (
      payload: types.FlowsAvatarProcessSetModalOpenStateAction["payload"]
    ) => ({
      type: types.FlowsAvatarProcessSetModalOpenState,
      payload,
    }),
    select: (
      payload: types.FlowsAvatarProcessUpdateValueAction["payload"]
    ) => ({
      type: types.FlowsAvatarProcessUpdateValue,
      payload,
    }),
    setFetching: (
      payload: types.FlowsAvatarProcessSetFetchingAction["payload"]
    ) => ({
      type: types.FlowsAvatarProcessSetFetching,
      payload,
    }),
    $submit:
      (): ThunkAction<any, RootState, any, any> =>
      async (dispatcher, getState) => {
        const { di, auth, achievements } = getState()

        const avatar = auth.flows.avatar.selected

        if (!avatar)
          return dispatcher(
            actions.notifications.create({
              type: "info",
              message: "avatar/update/empty",
            })
          )

        if (!auth.user) {
          return dispatcher(
            actions.notifications.create({
              type: "info",
              message: "avatar/update/not-logged",
            })
          )
        }

        dispatcher(flows.avatar.setFetching({ fetching: true }))

        await di.AuthRepository.updateProfile({ avatar })
        await di.PointsRepository.updateProfile({ avatar })

        dispatcher(
          authenticate({
            user: {
              ...auth.user,
              avatar,
            },
          })
        )

        dispatcher(flows.avatar.setFetching({ fetching: false }))
        dispatcher(flows.avatar.setModalOpen({ isOpen: false }))

        dispatcher(
          actions.notifications.create({
            type: "success",
            message: "avatar/updated",
          })
        )
      },
  },

  // ANCHOR Username Update
  username: {
    setModalOpen: (
      payload: types.FlowsUsernameProcessSetModalOpenStateAction["payload"]
    ) => ({
      type: types.FlowsUsernameProcessSetModalOpenState,
      payload,
    }),
    change: (
      payload: types.FlowsUsernameProcessUpdateValueAction["payload"]
    ) => ({
      type: types.FlowsUsernameProcessUpdateValue,
      payload,
    }),
    setFetching: (
      payload: types.FlowsUsernameProcessSetFetchingAction["payload"]
    ) => ({
      type: types.FlowsUsernameProcessSetFetching,
      payload,
    }),
    $submit:
      (): ThunkAction<any, RootState, any, any> =>
      async (dispatcher, getState) => {
        const { di, auth } = getState()

        const username = auth.flows.username.value

        if (!username)
          return dispatcher(
            actions.notifications.create({
              type: "info",
              message: "username/update/empty",
            })
          )

        if (!auth.user) {
          return dispatcher(
            actions.notifications.create({
              type: "info",
              message: "username/update/not-logged",
            })
          )
        }

        if (!/^[a-z0-9_\-\.]+$/gi.test(username) || username.length < 3) {
          return dispatcher(
            actions.notifications.create({
              type: "info",
              message: "username/update/bad-formed",
              timeout: 5000,
            })
          )
        }

        dispatcher(flows.username.setFetching({ fetching: true }))

        await di.AuthRepository.updateProfile({ username })
        await di.PointsRepository.updateProfile({ username })

        dispatcher(
          authenticate({
            user: {
              ...auth.user,
              username,
            },
          })
        )

        dispatcher(flows.username.setFetching({ fetching: false }))
        dispatcher(flows.username.setModalOpen({ isOpen: false }))

        dispatcher(
          actions.notifications.create({
            type: "success",
            message: "username/updated",
          })
        )
      },
  },
}

export const $sendForgotPasswordEmail =
  (email: string): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState()

    dispatcher(actions.auth.authenticateFetching({ is_fetching: true }))

    await di.AuthRepository.sendForgotPasswordEmail({
      email,
      lang: lang.lang,
      destinationUrl: di.LocationService.getOrigin() + getSigninUrl(lang.lang),
    })

    dispatcher(actions.auth.authenticateFetching({ is_fetching: false }))

    di.LocationService.navigate(getSigninUrl(lang.lang))

    dispatcher(
      actions.notifications.create({
        type: "success",
        message: "notifications/forgot-password/email-sent",
        timeout: 5000,
      })
    )

    di.AnalyticsService.send({
      action: "forgot_password",
      category: "authentication",
    })
  }

export const $applyToBeta =
  (platform: "android" | "ios"): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth } = getState()

    di.AuthRepository.applyToBeta({ platform, user_id: auth.user.id })
  }
