import * as types from "./types"

interface GlobalState {
  ads: {
    show: boolean
    isAlreadyFetched: boolean
  }
  isPremium: boolean
  isNativeApp: boolean
  current_web_version: string | null
  online_web_version: string | null
  online_native_version: string | null
  current_native_version: string | null
}

const initialState: GlobalState = {
  ads: {
    isAlreadyFetched: false,
    show: false,
  },
  isPremium: false,
  isNativeApp: false,
  current_web_version: null,
  online_web_version: null,
  online_native_version: null,
  current_native_version: null,
}

export function globalReducer(
  state = initialState,
  action: types.GlobalActionTypes,
): GlobalState {
  if (action.type === types.storeAds) {
    return {
      ...state,
      ads: {
        isAlreadyFetched: true,
        show: action.payload.showAds,
      },
    }
  }

  if (action.type === types.storeIsPremium) {
    return {
      ...state,
      isPremium: action.payload.isPremium,
    }
  }

  if (action.type === types.storeIsNativeApp) {
    return {
      ...state,
      isNativeApp: action.payload.isNativeApp,
    }
  }

  if (action.type === types.global_set_online_native_version) {
    return {
      ...state,
      current_native_version: action.payload.current_native_version,
      online_native_version: action.payload.online_native_version,
    }
  }

  if (action.type === types.global_set_deployed_git_version) {
    return {
      ...state,
      current_web_version: action.payload.current_web_version,
      online_web_version: action.payload.online_web_version,
    }
  }

  return state
}
