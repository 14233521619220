import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { DailyEntity, UserDailyEntity } from "../entities/DailyEntity"
import { IDailyRepository } from "../interfaces/IDailyRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseDailyRepository
  extends FirebaseUtils
  implements IDailyRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "daily"

  async getUserId(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const firebase = this.firebase.auth()
      firebase.onAuthStateChanged(function (user) {
        if (user) return resolve(user.uid)

        return resolve(null)
      })
    })
  }

  async findRanking(params: { daily: string }): Promise<DailyEntity[]> {
    const db = this.firebase.database()

    const response = await getDocs(
      query(
        collection(db, this.collection, params.daily, "ranking"),
        orderBy("duration", "asc"),
        limit(3),
      ),
    )

    return this.mapQuerySnapshot<DailyEntity>(response)
  }

  async findSelfRanking(params: {
    dailyId: string
    userId: string
  }): Promise<DailyEntity | null> {
    const db = this.firebase.database()
    const userId = await this.getUserId()

    if (!userId) return null

    const response = await getDoc(
      doc(db, this.collection, params.dailyId.toString(), "ranking", userId),
    )

    if (!response.exists()) return null

    const data = response.data()

    return data as DailyEntity
  }

  async storeDailyResult(params: DailyEntity): Promise<DailyEntity> {
    const db = this.firebase.database()
    const document = doc(
      db,
      this.collection,
      params.dailyId,
      "ranking",
      params.userId,
    )

    const results = await getDoc(document)

    if (!results.exists()) await setDoc(document, params)
    else if (results.data().duration > params.duration) {
      await setDoc(document, params, { merge: true })
    }

    if (params.userId !== "anonymous")
      this.storeDailyOnUserStats({
        dailyId: params.dailyId,
        level: params.level,
        userId: params.userId,
        isBest: false,
        date: new Date(params.dailyId.split(":")[0]),
      })

    return params
  }

  private async storeDailyOnUserStats(
    params: UserDailyEntity,
  ): Promise<UserDailyEntity> {
    const db = this.firebase.database()

    await setDoc(
      doc(
        db,
        "users",
        params.userId,
        `dailies:${params.level}`,
        params.dailyId,
      ),
      params,
    )

    return params
  }

  async fetch(params: { userId: string }): Promise<{ points: number }> {
    const db = this.firebase.database()

    const response = await getDoc(doc(db, this.collection, params.userId))

    if (!response.exists()) return { points: 0 }

    const data = response.data()

    return {
      points: data?.points || 0,
    }
  }

  async getGamesFromMonthOfUser(params: {
    userId: string
    interval: { start: Date; end: Date }
    level?: string
  }): Promise<UserDailyEntity[]> {
    const db = this.firebase.database()

    const response = await getDocs(
      query(
        collection(db, "users", params.userId, `dailies:${params.level}`),
        where("date", ">=", params.interval.start),
        where("date", "<=", params.interval.end),
      ),
    )

    return this.mapQuerySnapshot<UserDailyEntity>(response).map((daily) => ({
      ...daily,
      // @ts-ignore
      date: daily.date.toDate(),
    }))
  }

  async store(params: DailyEntity): Promise<DailyEntity> {
    const db = this.firebase.database()
    const document = doc(
      db,
      this.collection,
      params.userId,
      "ranking",
      params.userId,
    )

    await setDoc(document, params)

    return params
  }
}
