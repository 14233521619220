import { Capacitor } from "@capacitor/core"

export const IS_NATIVE = process.env.IS_NATIVE === "true"

export const useEnv = () => {
  const isIos = Capacitor.getPlatform() === "ios"
  const isAndroid = Capacitor.getPlatform() === "android"

  return {
    isNative: IS_NATIVE,
    isIos: isIos,
    isAndroid: isAndroid,
  }
}
