import {
  UserEntity,
  UserWithOptionalProfileEntity,
} from "../../../entities/UserEntity"

export const fetching = "REDUX_AUTH_FETCHING"
export interface fetchingAction {
  type: typeof fetching
}

export const fetchEnd = "REDUX_AUTH_FETCH_END"
export interface fetchEndAction {
  type: typeof fetchEnd
}

export const open = "REDUX_AUTH_OPEN"

export interface openAction {
  type: typeof open
}

export const close = "REDUX_AUTH_CLOSE"

export interface closeAction {
  type: typeof close
}

export const edit = "REDUX_AUTH_EDIT"
export interface editAction {
  type: typeof edit
  payload: { user: UserWithOptionalProfileEntity }
}

export const authenticate = "REDUX_AUTH_AUTHENTICATE"
export interface authenticateAction {
  type: typeof authenticate
  payload: { user: UserEntity }
}

export const authenticateFetching = "REDUX_AUTH_AUTHENTICATE_FETCHING"
export interface authenticateFetchingAction {
  type: typeof authenticateFetching
  payload: { is_fetching: boolean }
}

export const forgot = "REDUX_AUTH_FORGOT"
export interface forgotAction {
  type: typeof forgot
}

export const logout = "REDUX_AUTH_LOGOUT"
export interface logoutAction {
  type: typeof logout
}

export const FlowsResetType = "REDUX_AUTH_FLOW_RESET"
export interface FlowsResetAction {
  type: typeof FlowsResetType
}

export const setWillBeRemovedAt = "REDUX_AUTH_SET_WILL_BE_REMOVED_AT"
export interface setWillBeRemovedAtAction {
  type: typeof setWillBeRemovedAt
  payload: { will_be_removed_at: Date }
}

export const setWillBeRemovedAtFetching =
  "REDUX_AUTH_SET_WILL_BE_REMOVED_AT_FETCHING"
export interface setWillBeRemovedAtFetchingAction {
  type: typeof setWillBeRemovedAtFetching
  payload: { is_fetching: boolean }
}

export type AuthGlobalActionTypes =
  | FlowsResetAction
  | logoutAction
  | logoutAction
  | forgotAction
  | setWillBeRemovedAtAction
  | setWillBeRemovedAtFetchingAction
  | authenticateAction
  | editAction
  | closeAction
  | openAction
  | fetchingAction
  | fetchEndAction
  | authenticateFetchingAction
