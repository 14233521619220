import { ReactElement } from "react"
import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

type Props = {
  langKey: string
  children: ReactElement
  setDynamicLanguage?: boolean
}

const mapState = (state: RootState, props: Props) => ({
  langKey: props.langKey,
  children: props.children,
  setDynamicLanguage: props.setDynamicLanguage,
})

const mapDispatch = (dispatch: any, props: Props) => ({
  onMount: (lang: string) => {
    dispatch(actions.lang.store({ lang }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
