import React, { ReactElement, FC } from "react"
import { Link as GatsbyLink, navigate } from "@reach/router"

type Props = {
  url: string
  title?: string
  className?: string
  rel?: "follow" | "nofollow" | "obfuscated"
  target?: "self" | "blank"
  children: any
}

const Obfuscation: React.FC<
  Props & {
    children: React.ReactNode
    linkChildren: React.ReactNode
  }
> = (props) => {
  if (props.rel !== "obfuscated") return props.children

  return (
    <span
      onClick={() => {
        if (props.target === "blank") return window.open(props.url, "_blank")
        window.location.href = props.url
      }}
      className={(props.className || "") + " cursor-pointer"}
      children={props.linkChildren}
    ></span>
  )
}

export const Link: FC<Props> = (props) => {
  if (props.url?.startsWith("/"))
    return (
      <Obfuscation {...props} linkChildren={props.children}>
        <GatsbyLink
          to={props.url}
          {...(["nofollow", "sponsored"].includes(props.rel)
            ? { rel: props.rel }
            : {})}
          target={props.target === "blank" ? "_blank" : "_self"}
          className={props.className || ""}
          title={props.title}
        >
          {props.children}
        </GatsbyLink>
      </Obfuscation>
    )

  return (
    <Obfuscation {...props} linkChildren={props.children}>
      <a
        href={props.url}
        {...(["nofollow", "sponsored"].includes(props.rel)
          ? { rel: props.rel }
          : {})}
        target={props.target === "blank" ? "_blank" : "_self"}
        className={props.className || ""}
        title={props.title}
      >
        {props.children}
      </a>
    </Obfuscation>
  )
}
