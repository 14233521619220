import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { DailyEntity, UserDailyEntity } from "../entities/DailyEntity"

import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import { IStreaksRepository } from "../interfaces/IStreaksRepository"
import { StreakEntity } from "../entities/StreakEntity"
import dayjs from "dayjs"

export class FirebaseStreaksRepository
  extends FirebaseUtils
  implements IStreaksRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async create(params: Omit<StreakEntity, "id">): Promise<StreakEntity> {
    const db = this.firebase.database()
    const id = dayjs(params.date).format("YYYY-MM-DD")
    const document = doc(db, "users", params.user_id, "streaks", id)

    await setDoc(document, params, { merge: true })

    return { ...params, id }
  }

  async fetch(params: {
    user_id: string
    interval?: { start: Date; end: Date }
  }): Promise<StreakEntity[]> {
    const db = this.firebase.database()

    const response = await getDocs(
      query(
        collection(db, "users", params.user_id, "streaks"),
        where("date", ">=", params.interval?.start || new Date("1970-01-01")),
        where("date", "<=", params.interval?.end || new Date()),
      ),
    )

    return this.mapQuerySnapshot<StreakEntity>(response).map((streak) => ({
      ...streak,
      // @ts-ignore
      date: streak.date.toDate(),
    }))
  }
}
