import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorage-keys"

export const store = (
  payload: types.StoreAction["payload"]
): types.LangActionTypes => ({
  type: types.Store,
  payload,
})

export const $setLanguage =
  (params: { lang: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState()

    di.LocalStorageRepository.store(
      LOCAL_STORAGE_KEYS.USER_LANGUAGE,
      params.lang
    )

    dispatcher(store(params))
  }
