import React from "react"
import { CustomIntlProvider } from "./src/components/CustomIntlProvider/CustomIntlProvider"
import { WhatsNewModal } from "./src/components/WhatsNewModal/WhatsNewModal"
import { PremiumModal } from "./src/components/PremiumModal/PremiumModal"
import { SubscriptionSuccessModal } from "./src/components/SubscriptionSuccessModal/SubscriptionSuccessModal"

export default ({ element, props }) => {
  return (
    <CustomIntlProvider langKey={props.pageContext.langKey as string}>
      <div className="text-slate-900">
        <WhatsNewModal {...(props.pageContext.news || {})} />
        <PremiumModal />
        <SubscriptionSuccessModal />
        {element}
      </div>
    </CustomIntlProvider>
  )
}
