import * as types from "./types"
import { getCalendar } from "../../utils/date"
import { PointsEntity } from "../../entities/PointsEntity"
import { StreakEntity } from "../../entities/StreakEntity"
import dayjs from "dayjs"
import { calculate_streak } from "../../utils/calculate-streak"

interface DailyState {
  fetching: boolean
  selected: Date
  month: number
  year: number
  level: string
  currentMonth: Date
  daysAchieved: Date[]
  calendar: Array<{ date: Date; isCurrentMonth?: boolean }>
  users: Array<PointsEntity & { duration: number }>
  user: (PointsEntity & { duration: number }) | null
  isUserAlreadyPlayedDailyGame: boolean | null
  streaks: StreakEntity[]
  current_streak: number
}

const initialState: DailyState = {
  fetching: false,
  level: "easy",
  selected: new Date(),
  daysAchieved: [],
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  currentMonth: new Date(),
  isUserAlreadyPlayedDailyGame: null,
  calendar: getCalendar({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  }),
  users: [],
  user: null,
  streaks: [],
  current_streak: 0,
}

export function dailyReducer(
  state = initialState,
  action: types.DailyActionTypes,
): DailyState {
  if (action.type === types.DailyFetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === types.StoreStreaks) {
    return {
      ...state,
      streaks: action.payload.streaks,
      current_streak: calculate_streak(action.payload.streaks),
    }
  }

  if (action.type === types.DailyFetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  if (action.type === types.StoreDailyPlayedStatus) {
    return {
      ...state,
      isUserAlreadyPlayedDailyGame: action.payload.status,
    }
  }

  if (action.type === types.StoreDaysAchieved) {
    return {
      ...state,
      daysAchieved: action.payload.daysAchieved,
    }
  }

  if (action.type === types.SelectLevel) {
    return {
      ...state,
      level: action.payload.level,
    }
  }

  if (action.type === types.StoreUsersRanking) {
    return {
      ...state,
      users: action.payload,
    }
  }

  if (action.type === types.StoreUserRanking) {
    return {
      ...state,
      user: action.payload,
    }
  }

  if (action.type === types.SelectDay) {
    return {
      ...state,
      selected: action.payload.date,
    }
  }

  if (action.type === types.PreviousMonth) {
    const month = state.month === 0 ? 11 : state.month - 1
    const year = month === 11 ? state.year - 1 : state.year

    return {
      ...state,
      month,
      year,
      currentMonth: new Date(year, month, 1),
      calendar: getCalendar({ year, month }),
    }
  }

  if (action.type === types.NextMonth) {
    const month = state.month === 11 ? 0 : state.month + 1
    const year = month === 0 ? state.year + 1 : state.year

    return {
      ...state,
      month,
      year,
      currentMonth: new Date(year, month, 1),
      calendar: getCalendar({ year, month }),
    }
  }

  return state
}
