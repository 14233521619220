import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { IStatsRepositoryPeriod } from "../../interfaces/IStatsRepository"
import { Modules } from "../../interfaces/IModule"
import { StatEntity } from "../../entities/StatEntity"

export const fetching = (): types.StatsActionTypes => ({
  type: types.Fetching,
})

export const fetchEnd = (): types.StatsActionTypes => ({
  type: types.FetchEnd,
})

export const store = (
  payload: types.StoreAction["payload"]
): types.StatsActionTypes => ({
  type: types.Store,
  payload,
})

export const $sync =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (auth.user?.id) {
      await di.StatsRepository.sync({
        userId: auth.user?.id,
      })
    }
  }

const fetch_stats_by_user_id = async (params: {
  period: IStatsRepositoryPeriod
  user_id?: string | null
  di: Modules
}): Promise<StatEntity[]> => {
  if (!params.user_id) return []

  return params.di.StatsRepository.findAll({
    period: params.period,
    userId: params.user_id,
  })
}

export const $findAll =
  (params?: { user_id?: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, stats, auth, pricing } = getState()

    dispatcher(fetching())

    const data = await fetch_stats_by_user_id({
      period: stats.period,
      user_id: params?.user_id,
      di,
    })

    dispatcher(store({ stats: data }))
    dispatcher(fetchEnd())
  }

export const changePeriod = (
  payload: types.ChangePeriodAction["payload"]
): types.StatsActionTypes => ({
  type: types.ChangePeriod,
  payload,
})

export const $changePeriod =
  (params: {
    period: IStatsRepositoryPeriod
    user_id?: string
  }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(changePeriod(params))
    dispatcher($findAll(params))
  }
