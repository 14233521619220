import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import { Capacitor } from "@capacitor/core"
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth"

import {
  AuthenticateReturnErrorType,
  AuthenticateReturnType,
  AuthenticateWithGoogleReponse,
  AuthenticateWithLinkReturnErrorType,
  AuthenticateWithLinkReturnType,
  GetRequestRemoveProfileReturnType,
  IAuthRepository,
  IsAuthenticatedReturnType,
  RegisterWithPasswordReturnType,
  RepositoryResponse,
  SendForgotPasswordEmailReturnType,
  SendSignInLinkToEmailErrorType,
  SendSignInLinkToEmailType,
  UpdateProfileReturnType,
} from "../interfaces/IAuthRepository"

import {
  signInWithPopup,
  sendSignInLinkToEmail,
  signInWithCredential,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  updateProfile,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth"
import { UserWithOptionalProfileEntity } from "../entities/UserEntity"
import { deleteDoc, getDoc, setDoc } from "firebase/firestore"
import { doc } from "firebase/firestore"

export class FirebaseAuthRepository
  extends FirebaseUtils
  implements IAuthRepository
{
  constructor(private firebase: Firebase) {
    super()
    if (Capacitor.isNativePlatform()) {
      GoogleAuth.initialize()
    }
  }

  async authenticateWithGoogle(): Promise<AuthenticateWithGoogleReponse> {
    const firebase = this.firebase.auth()
    const provider = this.firebase.getGoogleProvider()

    try {
      if (Capacitor.isNativePlatform()) {
        const googleUser = await GoogleAuth.signIn()

        const credential = GoogleAuthProvider.credential(
          googleUser.authentication.idToken
        )
        const response = await signInWithCredential(
          this.firebase.auth(),
          credential
        )

        const isNewUser = false
        const avatarFromGoogle = response.user?.photoURL?.includes("google")

        return {
          authenticated: true,
          user: {
            id: response.user?.uid as string,
            email: response.user?.email as string,
            avatar:
              isNewUser || avatarFromGoogle
                ? null
                : response.user?.photoURL || null,
            username:
              isNewUser || avatarFromGoogle
                ? null
                : response.user?.displayName || null,
          },
        }
      }

      const response = await signInWithPopup(firebase, provider)
      const isNewUser = false
      // const isNewUser = response.additionalUserInfo?.isNewUser
      const avatarFromGoogle = response.user?.photoURL?.includes("google")

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
          avatar:
            isNewUser || avatarFromGoogle
              ? null
              : response.user?.photoURL || null,
          username:
            isNewUser || avatarFromGoogle
              ? null
              : response.user?.displayName || null,
        },
      }
    } catch (e) {
      alert(e)

      const error = e as {
        code:
          | "auth/expired-action-code"
          | "auth/invalid-email"
          | "auth/user-disabled"
      }

      const codes: {
        [key in typeof error.code]: any
      } = {
        "auth/user-disabled": "USER_DISABLED",
        "auth/expired-action-code": "EMAIL_BAD_FORMATTED",
        "auth/invalid-email": "EMAIL_BAD_FORMATTED",
      }

      return {
        error: codes[error.code],
        authenticated: false,
      }
    }
  }

  async isAuthenticated(): Promise<IsAuthenticatedReturnType> {
    return new Promise((resolve, reject) => {
      const firebase = this.firebase.auth()
      firebase.onAuthStateChanged(function (user) {
        if (user)
          return resolve({
            authenticated: true,
            user: {
              id: user.uid,
              email: user.email as string,
              avatar: user.photoURL,
              username: user.displayName,
            },
          })
        return resolve({ authenticated: false })
      })
    })
  }

  async logout() {
    try {
      if (Capacitor.isNativePlatform()) {
        await GoogleAuth.signOut()
      }

      await signOut(this.firebase.auth())

      return { succeed: true }
    } catch (e) {
      return { succeed: true }
    }
  }

  async sendSignInLinkToEmail(params: {
    email: string
    lang: string
    destinationUrl: string
  }): Promise<SendSignInLinkToEmailType> {
    const auth = this.firebase.auth()

    auth.languageCode = params.lang

    try {
      await sendSignInLinkToEmail(auth, params.email, {
        handleCodeInApp: true,
        url: params.destinationUrl,
      })

      return { succeed: true }
    } catch (e) {
      console.error(e)
      const error = e as {
        code:
          | "auth/argument-error"
          | "auth/invalid-email"
          | "auth/missing-android-pkg-name"
          | "auth/missing-continue-uri"
          | "auth/missing-ios-bundle-id"
          | "auth/invalid-continue-uri"
          | "auth/unauthorized-continue-uri"
      }

      const codes: {
        [key in typeof error.code]: SendSignInLinkToEmailErrorType
      } = {
        "auth/invalid-email": "INVALID_EMAIL",
        "auth/argument-error": "ERROR_SERVER",
        "auth/missing-android-pkg-name": "ERROR_SERVER",
        "auth/missing-continue-uri": "ERROR_SERVER",
        "auth/missing-ios-bundle-id": "ERROR_SERVER",
        "auth/invalid-continue-uri": "ERROR_SERVER",
        "auth/unauthorized-continue-uri": "ERROR_SERVER",
      }

      return {
        error: codes[error.code] || "ERROR_SERVER",
        sourceErrorCode: error.code,
        succeed: false,
      }
    }
  }

  async authenticateWithSigninLink(params: {
    email: string
    link: string
  }): Promise<AuthenticateWithLinkReturnType> {
    const auth = this.firebase.auth()

    if (!isSignInWithEmailLink(auth, params.link))
      return {
        error: "EXPIRED_CODE",
        authenticated: false,
      }

    try {
      const response = await signInWithEmailLink(
        auth,
        params.email,
        params.link
      )

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
          avatar: response.user?.photoURL || null,
          username: response.user?.displayName || null,
        },
      }
    } catch (e) {
      const error = e as {
        code:
          | "auth/expired-action-code"
          | "auth/invalid-email"
          | "auth/user-disabled"
      }

      const codes: {
        [key in typeof error.code]: AuthenticateWithLinkReturnErrorType
      } = {
        "auth/expired-action-code": "EXPIRED_CODE",
        "auth/invalid-email": "EXPIRED_CODE",
        "auth/user-disabled": "USER_DISABLED",
      }

      return {
        error: codes[error.code] || "USER_DISABLED",
        authenticated: false,
        sourceErrorCode: error.code,
      }
    }
  }

  async updateProfile(params: {
    avatar?: string | undefined
    username?: string | undefined
  }): Promise<UpdateProfileReturnType> {
    const firebase = this.firebase.auth()

    const user = firebase.currentUser

    if (!user) return { updated: false, error: "auth/not-authenticated" }

    try {
      const profile = {
        ...(params.username ? { displayName: params.username } : {}),
        ...(params.avatar ? { photoURL: params.avatar } : {}),
      }

      await updateProfile(user, profile)

      return {
        updated: true,
        user: {
          id: user.uid,
          email: user.email as string,
          avatar: user.photoURL as string,
          username: user.displayName as string,
          ...params,
        },
      }
    } catch (e) {
      const error = e as string

      return {
        updated: false,
        error: "auth/update-profile/error-server",
        sourceErrorCode: error,
      }
    }
  }

  async authenticateWithPassword(params: {
    email: string
    password: string
  }): Promise<AuthenticateReturnType> {
    const auth = this.firebase.auth()

    try {
      const response = await signInWithEmailAndPassword(
        auth,
        params.email,
        params.password
      )

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
          avatar: response.user?.photoURL || null,
          username: response.user?.displayName || null,
        },
      }
    } catch (e) {
      return {
        authenticated: false,
        error: "auth/wrong-password",
      }
    }
  }

  async sendForgotPasswordEmail(params: {
    email: string
    lang: string
    destinationUrl: string
  }): Promise<SendForgotPasswordEmailReturnType> {
    try {
      const auth = this.firebase.auth()
      auth.languageCode = params.lang

      await sendPasswordResetEmail(auth, params.email, {
        url: params.destinationUrl,
      })

      return { succeed: true }
    } catch (e) {
      const error = e.message as string

      return {
        error: this.getErrorMessage(error),
        succeed: false,
      }
    }
  }

  async createAccountWithPassword(params: {
    email: string
    password: string
    language: string
  }): Promise<RegisterWithPasswordReturnType> {
    const auth = this.firebase.auth()

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        params.email,
        params.password
      )

      await sendEmailVerification(response.user)

      return { success: true }
    } catch (e) {
      const error = e.message as string

      return {
        success: false,
        error: this.getErrorMessage(error),
      }
    }
  }

  private getErrorMessage(error: string): AuthenticateReturnErrorType {
    if (error.includes("auth/email-already-in-use"))
      return "auth/email-already-in-use"

    if (error.includes("auth/invalid-email")) return "auth/invalid-email"

    if (error.includes("auth/weak-password")) return "auth/weak-password"

    if (error.includes("auth/invalid-password")) return "auth/invalid-password"

    return "auth/unknown-error"
  }

  async getRequestRemoveProfile(params: {
    user_id: string
  }): Promise<GetRequestRemoveProfileReturnType> {
    try {
      const db = this.firebase.database()
      const docRef = doc(db, "users_to_remove", params.user_id)
      const docSnap = await getDoc(docRef)

      const willBeRemovedAt = docSnap.data()?.will_be_removed_at?.toDate()

      return {
        success: true,
        body: {
          will_be_removed_at: willBeRemovedAt
            ? new Date(willBeRemovedAt)
            : null,
        },
      }
    } catch (e) {
      return {
        success: false,
        error: e.message,
      }
    }
  }

  async storeRequestRemoveProfile(params: {
    user_id: string
    will_be_removed_at: Date
  }): Promise<RepositoryResponse<{ will_be_removed_at: Date }>> {
    try {
      const db = this.firebase.database()
      const docRef = doc(db, "users_to_remove", params.user_id)
      await setDoc(docRef, { will_be_removed_at: params.will_be_removed_at })

      return {
        success: true,
        body: {
          will_be_removed_at: params.will_be_removed_at,
        },
      }
    } catch (e) {
      return {
        success: false,
        error: e.message,
      }
    }
  }

  async removeRequestRemoveProfile(params: {
    user_id: string
  }): Promise<RepositoryResponse<{ will_be_removed_at: null }>> {
    try {
      const db = this.firebase.database()
      const docRef = doc(db, "users_to_remove", params.user_id)
      await deleteDoc(docRef)

      return {
        success: true,
        body: { will_be_removed_at: null },
      }
    } catch (e) {
      return { success: false, error: e.message }
    }
  }

  async applyToBeta(params: {
    platform: "android" | "ios"
    user_id: string
  }): Promise<void> {
    try {
      const db = this.firebase.database()
      const docRef = doc(db, "beta_users", params.user_id)

      await setDoc(docRef, {
        platform: params.platform,
        user_id: params.user_id,
        applied_at: new Date(),
      })
    } catch (e) {
      alert(e)
      console.error(e)
    }
  }
}
