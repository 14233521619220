export const storeAds = "storeAds"
export interface storeAdsAction {
  type: typeof storeAds
  payload: {
    showAds: boolean
  }
}

export const storeIsPremium = "storeIsPremium"
export interface storeIsPremiumAction {
  type: typeof storeIsPremium
  payload: {
    isPremium: boolean
  }
}

export const storeIsNativeApp = "storeIsNativeApp"
export interface storeIsNativeAppAction {
  type: typeof storeIsNativeApp
  payload: {
    isNativeApp: boolean
  }
}

export const global_set_online_native_version =
  "global_set_online_native_version"
export interface global_set_online_native_version_action {
  type: typeof global_set_online_native_version
  payload: {
    online_native_version: string
    current_native_version: string
  }
}

export const global_set_deployed_git_version = "global_set_deployed_git_version"
export interface global_set_deployed_git_version_action {
  type: typeof global_set_deployed_git_version
  payload: {
    current_web_version: string
    online_web_version: string
  }
}

export type GlobalActionTypes =
  | storeAdsAction
  | storeIsPremiumAction
  | storeIsNativeAppAction
  | global_set_deployed_git_version_action
  | global_set_online_native_version_action
